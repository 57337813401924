import TopNav from "@/components/dashboard/general/navigation/TopNav";


import { ReactNode } from "react";

export default function WithoutNavBarLayout({ children }: { children: ReactNode }) {
    return (
        <div className="h-full flex flex-col">
            {/* Main Content */}
            <main className="flex-1 pt-16 overflow-auto">
                <div className="px-4 sm:px-6 lg:px-8">
                    {children}
                </div>
            </main>
        </div>
    );
}
