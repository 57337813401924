import { Observable } from "@apollo/client";
import { Client, ClientOptions, createClient } from "graphql-ws";
import { ApolloLink, Operation, FetchResult } from "@apollo/client/core";

// Create an adapter for Apollo Client to use graphql-ws
export const createGraphQLWSLink = (options: ClientOptions): ApolloLink => {
    const client: Client = createClient(options);

    return new ApolloLink((operation: Operation) => {
        return new Observable<FetchResult>((observer) => {
            const unsubscribe = client.subscribe(
                {
                    query: operation.query.loc?.source.body || "",
                    variables: operation.variables,
                    operationName: operation.operationName,
                },
                {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                }
            );

            return () => unsubscribe();
        });
    });
};
