import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth";
import CircularLoader from "@/components/loading/LoadingComponent";

export default function TwitchCallbackPage() {
    const [searchParams] = useSearchParams();
    const { completeTwitchLogin } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [called, setCalled] = useState(false); // Prevent duplicate calls

    useEffect(() => {
        const handleOAuth = async () => {
            const code = searchParams.get("code");

            if (!code) {
                setError("No authorization code provided.");
                return;
            }

            if (called) return; // Prevent duplicate execution
            setCalled(true);

            setLoading(true);
            try {
                await completeTwitchLogin(code); // Call the context method
            } catch (err) {
                console.error("OAuth error:", err);
                setError("An error occurred during the OAuth process. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        handleOAuth();
    }, [completeTwitchLogin, navigate, searchParams, called]);

    if (loading) return <CircularLoader />;

    return (
        <div>
            {error ? (
                <div className="text-center text-red-600">
                    <h1>Error</h1>
                    <p>{error}</p>
                    <button onClick={() => navigate("/")} className="mt-4">
                        Go to Login
                    </button>
                </div>
            ) : (
                <h1>Redirecting...</h1>
            )}
        </div>
    );
}
