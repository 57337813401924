import React, { useState, useEffect } from "react";
import SVGEditor from "@/components/dashboard/general/SVGEditor";

interface ForgeShieldPageProps {
    rawSvgContent: string;
}

export default function ForgeShieldPage({
    rawSvgContent,
}: ForgeShieldPageProps) {
    const [svgContent, setSvgContent] = useState<string | null>(null);

    // Set the SVG content directly from the imported string
    useEffect(() => {
        setSvgContent(rawSvgContent);
    }, [rawSvgContent]);

    if (!svgContent) {
        return <div>Loading...</div>; // Display a loading state
    }

    return (
        <div className="flex h-full flex-col items-center justify-center">
            <SVGEditor svgContent={svgContent} />
        </div>
    );
}
