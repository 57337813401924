import { useAuth } from "@/hooks/auth/useAuth";
import { convertLetters, convertLettersSafely } from "@/utils/letterConversion";
import NamePlateComponent from "@/components/dashboard/general/svgs/NamePlateComponent";

export default function ForgePage() {
    const { user } = useAuth();

    const title = convertLetters("Welcome to the Forge");
    const subtitleText = convertLetters(
        "Choose your shield and forge your legacy!"
    );

    return (
        <>
            <div className="mt-5 grid gap-20 sm:grid-cols-5">
                {/* Align vertically and horizontally center */}
                <div className="col-span-5 flex items-center justify-center justify-items-center pt-10">
                    <h1
                        className="font-prviking border-text text-center text-9xl text-brand"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </div>
                <div className="col-span-5 flex items-center justify-center justify-items-center">
                    <h2 className="font-prviking border-text text-center text-7xl text-brand">
                        <span
                            dangerouslySetInnerHTML={{ __html: subtitleText }}
                        />
                    </h2>
                </div>

                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/berserkir">
                                <img
                                    src={`/shields/BERSERKIR.svg`}
                                    alt="Berserkir Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Berserkir"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/midgard">
                                <img
                                    src={`/shields/MIDGARD.svg`}
                                    alt="Midgard Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Midgard"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/kross">
                                <img
                                    src={`/shields/KROSS.svg`}
                                    alt="Kross Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Kross"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* ISAZ */}
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/isaz">
                                <img
                                    src={`/shields/ISAZ.svg`}
                                    alt="Isaz Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Isaz"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* GEBO */}
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/gebo">
                                <img
                                    src={`/shields/GEBO.svg`}
                                    alt="Gebo Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Gebo"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* NORBAUSTA */}
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/norbausta">
                                <img
                                    src={`/shields/NORBAUSTA.svg`}
                                    alt="Norbausta Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Norbausta"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* STJARNA */}
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/stjarna">
                                <img
                                    src={`/shields/STJARNA.svg`}
                                    alt="Stjarna Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Stjarna"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* NORBVESTUR */}
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/norbvestur">
                                <img
                                    src={`/shields/NORBVESTUR.svg`}
                                    alt="Norbvestur Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Norbvestur"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* URUZ */}
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/uruz">
                                <img
                                    src={`/shields/URUZ.svg`}
                                    alt="Uruz Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Uruz"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                {/* ZURU */}
                <div className="col-span-5 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="/forge/shields/zuru">
                                <img
                                    src={`/shields/ZURU.svg`}
                                    alt="Zuru Shield"
                                    className="h-80 w-80 rounded-lg border-8 border-brand p-4"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Zuru"
                                    className="absolute bottom-[-15px] left-1/2 z-10 -translate-x-1/2 transform"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                
            </div>
        </>
    );
}
