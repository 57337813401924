// We need a function that converts every letter to capitals execept the letters i, u and a which should always be lowercase.
// It should take in a string and return a string. It should respect spaces and special characters.
// If the letters i, u or a are uppercase, they should be converted to lowercase.
// It should keep html tags as they are.
// Ensure the character comparison is case-insensitive.
// Example:

// letterConversion("Hello World") => "HELLO WORLD
// letterConversion("I am a student") => "i aM a STuDENT

export function convertLetters(input: string): string {
    const lowerCaseExceptions = new Set(["i", "u", "a"]);

    return input.replace(/<[^>]*>|[^<>\s]/gi, (match) => {
        if (match.startsWith("<") && match.endsWith(">")) {
            return match; // Leave HTML tags unchanged
        }
        if (lowerCaseExceptions.has(match.toLowerCase())) {
            return match.toLowerCase(); // Convert exceptions to lowercase
        }
        return match.toUpperCase(); // Convert other letters to uppercase
    });
}

export function convertLettersSafely(input: string): string {
    // Similar to `convertLetters` but escapes HTML entities
    const escapeHtml = (unsafe: string): string =>
        unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");

    const converted = convertLetters(input);
    return escapeHtml(converted);
}
