import { gql } from "@apollo/client";

export const KILL_STATS_SUBSCRIPTION = gql`
    subscription MySubscription {
        stats {
            currentSessionAxeKills
            currentSessionBowKills
            currentSessionTkKills
            lifetimeAxeKills
            lifetimeBowKills
            lifetimeTkKills
        }
    }
`;