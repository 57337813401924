import { useAuth } from "@/hooks/auth/useAuth";
import { convertLetters, convertLettersSafely } from "@/utils/letterConversion";
import NamePlateComponent from "@/components/dashboard/general/svgs/NamePlateComponent";

export default function ShieldUploadedPage() {
    const { user } = useAuth();

    const title = convertLetters("Thanks for forging your shield");
    const twitchUsername = user
        ? convertLettersSafely(user?.twitchUsername)
        : "";
    const shield = user?.player?.shield || null;

    return (
        <>
            <div className="mt-5 grid gap-5 sm:grid-cols-4">
                {/* Align vertically and horizontally center */}
                <div className="col-span-4 flex items-center justify-center justify-items-center pt-10">
                    <h1
                        className="font-prviking border-text text-center text-9xl text-brand"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </div>
                <div className="col-span-4 flex items-center justify-center justify-items-center">
                    <h2 className="font-prviking border-text text-center text-7xl text-brand">
                        {twitchUsername && (
                            <span>
                                <br />
                                {twitchUsername}
                            </span>
                        )}
                    </h2>
                </div>


                {/* Profile */}
                <div className="col-span-4 sm:col-span-4">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <a href="#">
                                <img
                                    src={shield?.imageUrl}
                                    alt="Profile"
                                    className="h-96 w-96 rounded-lg border-8 border-brand"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
