import React, { useEffect, useState } from "react";
import { SVGProps } from "react";
import killCounterPng from "@/assets/kill_counter.png";

// Define the StatsData interface
interface StatsData {
    stats: {
        currentSessionAxeKills: number | null;
        currentSessionBowKills: number | null;
        currentSessionTkKills: number | null;
        lifetimeAxeKills: number;
        lifetimeBowKills: number;
        lifetimeTkKills: number;
    };
}

const KillCounterSVG: React.FC<SVGProps<SVGSVGElement> & StatsData> = (props) => {
    const { stats, ...svgProps } = props; // Destructure stats and other props
    const [base64Image, setBase64Image] = useState<string>("");

    console.log("KillCounterSVG stats:", stats);

    useEffect(() => {
        // Convert the image to base64 dynamically if not already handled by Vite
        fetch(killCounterPng)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setBase64Image(reader.result as string);
                };
                reader.readAsDataURL(blob);
            });
    }, []);

    if (!base64Image) {
        return null; // Optionally, show a loader or fallback component
    }

    return (
        <svg
            viewBox="0 0 940 1130"
            xmlns="http://www.w3.org/2000/svg"
            {...svgProps} // Spread other SVG props
        >
            <image href={base64Image} width="940" height="1130" />
            {/* Example of displaying the stats */}
            <text x="170" y="883" fontSize="50" fill="#efead8" textAnchor="middle" className="font-garamond">
                {stats.currentSessionBowKills ?? 0}
            </text>
            <text x="170" y="970" fontSize="50" fill="#cead38" textAnchor="middle" className="font-garamond">
                {stats.lifetimeBowKills ?? 0}
            </text>

            <text x="480" y="883" fontSize="50" fill="#efead8" textAnchor="middle" className="font-garamond">
                {stats.currentSessionTkKills ?? 0}
            </text>
            <text x="480" y="970" fontSize="50" fill="#cead38" textAnchor="middle" className="font-garamond">
                {stats.lifetimeTkKills ?? 0}
            </text>

            <text x="790" y="883" fontSize="50" fill="#efead8" textAnchor="middle" className="font-garamond">
                {stats.currentSessionAxeKills ?? 0}
            </text>
            <text x="790" y="970" fontSize="50" fill="#cead38" textAnchor="middle" className="font-garamond">
                {stats.lifetimeAxeKills ?? 0}
            </text>
        </svg>
    );
};

export default KillCounterSVG;
