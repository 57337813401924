import { Outlet } from "react-router-dom";
import { useEffect } from "react";

export default function AssetsLayout() {
    useEffect(() => {
        // Set the background when the component mounts
        // Make the body tag completely transparent, removing all background colors and images
        document.body.style.backgroundColor = "transparent";
        document.body.style.backgroundImage = "none";

        // Remove the body-overlay-on class from the body
        document.body.classList.remove("body-overlay-on");

        // Optional cleanup function to reset the background on unmount
        return () => {
            document.body.style.backgroundColor = "";
            document.body.style.backgroundImage = "";
            document.body.classList.add("body-overlay-on");
        };
    }, []);

    return (
        <>
            <Outlet />
        </>
    );
}
