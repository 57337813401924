import { AuthContextType } from "@/types/globalTypes";
import { createContext } from "react";

export const AuthContext = createContext<AuthContextType>({
    user: null,
    loading: true,
    isAuthenticated: false,
    fetchUserData: async () => {},
    loginUser: async () => {},
    logoutUser: async () => {},
    startTwitchLogin: async () => {},
    completeTwitchLogin: async () => {},
});