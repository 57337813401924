import { SiTwitch } from "@icons-pack/react-simple-icons";
import React from "react";
import { useAuth } from "@/hooks/auth/useAuth";
import { convertLetters } from "@/utils/letterConversion";

const SubscribePage: React.FC = () => {
    const { logoutUser } = useAuth();

    const title = convertLetters("Welcome to Vanderheim");
    const subtitle = convertLetters("To enter the Vanderheim Village");
    const subtitle2 = convertLetters("you must have been subscribed on Twitch either now or in the past.");
    const logoutText = convertLetters("Once you are subscribed, click the button below to sign out and then sign back in.");

    return (
        <div>

            {/* Centered Content Wrapper */}
            <div className="relative z-10 flex items-center justify-center h-full w-full">
                <div className="text-center">
                    <div className="mb-2">
                        <h2
                            className="font-prviking border-text text-center text-9xl text-brand"
                        >
                            {title}
                        </h2>
                        <h3
                            className="font-prviking border-text text-center text-7xl text-brand mt-32"
                        >
                            {subtitle}
                            <br />
                            {subtitle2}
                        </h3>
                        <button
                            className="bg-[#6441A4] text-white px-4 py-2 rounded-md"
                            onClick={() => {
                                window.open("https://www.twitch.tv/subs/vanderwoodtv", "_blank");
                            }}
                        >
                            <SiTwitch className="inline-block mr-2" />
                            Subscribe on Twitch
                        </button>
                        <h4 className="font-prviking border-text text-center text-5xl text-brand mt-52">
                            {logoutText}
                        </h4>
                        <button
                            className="bg-[#6441A4] text-white px-4 py-2 rounded-md"
                            onClick={logoutUser}
                        >
                            Sign Out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscribePage;
