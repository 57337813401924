import React, { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import { KILL_STATS_SUBSCRIPTION } from "@/graphql/subscriptions/gameSubscriptions";
import KillCounterSVG from "@/components/killCounter/KillCounterSVG";

interface StatsData {
    stats: {
        currentSessionAxeKills: number | null;
        currentSessionBowKills: number | null;
        currentSessionTkKills: number | null;
        lifetimeAxeKills: number;
        lifetimeBowKills: number;
        lifetimeTkKills: number;
    };
}

const HunterKillScoreboardPage: React.FC = () => {
    const { data, loading, error } = useSubscription<StatsData>(KILL_STATS_SUBSCRIPTION);

    if (loading) return <p>Loading subscription data...</p>;
    if (error) return <p>Error: {error.message}</p>;

    // Use default values if `data?.stats` is undefined
    const stats = data?.stats || {
        currentSessionAxeKills: 0,
        currentSessionBowKills: 0,
        currentSessionTkKills: 0,
        lifetimeAxeKills: 0,
        lifetimeBowKills: 0,
        lifetimeTkKills: 0,
    };

    return (
        <div className="">
            {/* Pass stats explicitly to the KillCounterSVG component */}
            <KillCounterSVG stats={stats} />
        </div>
    );
};

export default HunterKillScoreboardPage;
