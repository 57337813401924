import { Outlet } from "react-router-dom";
import { useEffect } from "react";

export default function ForgeLayout() {
    useEffect(() => {
        // Set the background image when the component mounts
        document.body.style.backgroundImage = "url('/forge.png')";
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';

        // Optional cleanup function to reset the background on unmount
        return () => {
            document.body.style.backgroundImage = '';
            document.body.style.backgroundSize = '';
            document.body.style.backgroundPosition = '';
        };
    }, []);

    return (
        <>
            <Outlet />
        </>
    );
}
