import { gql } from "@apollo/client";

export const START_TWITCH_AUTH_MUTATION = gql`
  mutation StartTwitchAuth {
    startTwitchAuth {
      url
    }
  }
`;

// Mutation to complete the Twitch OAuth flow
export const COMPLETE_TWITCH_AUTH_MUTATION = gql`
  mutation CompleteTwitchAuth($code: String!) {
    completeTwitchAuth(code: $code) {
      user {
        id
        twitchUsername
        email
        avatarUrl
        isStaff
        isSuperuser
      }
    }
  }
`;

export const LOGIN_USER_MUTATION = gql`
    mutation LoginUser($email: String!, $password: String!) {
        loginUser(email: $email, password: $password) {
            status
            message
            success
            user {
                firstName
                lastName
                emailVerified
            }
        }
    }
`;

export const LOGOUT_USER_MUTATION = gql`
    mutation LogoutUser {
        logoutUser {
            success
        }
    }
`;