import { createBrowserRouter } from "react-router-dom";

import berserkirSvg from "@/assets/shields/BERSERKIR.svg?raw";
import midgardSvg from "@/assets/shields/MIDGARD.svg?raw";
import geboSvg from "@/assets/shields/GEBO.svg?raw";
import isazSvg from "@/assets/shields/ISAZ.svg?raw";
import krossSvg from "@/assets/shields/KROSS.svg?raw";
import norbaustaSvg from "@/assets/shields/NORBAUSTA.svg?raw";
import norbvesturSvg from "@/assets/shields/NORBVESTUR.svg?raw";
import stjarnaSvg from "@/assets/shields/STJARNA.svg?raw";
import uruzSvg from "@/assets/shields/URUZ.svg?raw";
import zuruSvg from "@/assets/shields/ZURU.svg?raw";

import AuthGuard from "@/guards/AuthGuard";
import ShieldGuard from "@/guards/ShieldGuard";

import BaseLayout from "@/layouts/BaseLayout";
import DashboardLayout from "@/layouts/(dashboard)/DashboardLayout";
import ForgeLayout from "@/layouts/(forge)/ForgeLayout";
import AssetsLayout from "@/layouts/(assets)/AssetsLayout";

import DashboardPage from "@/pages/(dashboard)/DashboardPage";
import WallhallaViewer from "@/pages/(wallhalla)/WallhallaPage";
import AnniversaryPage from "@/pages/(wallhalla)/AnniversaryPage";
import RedirectToExternal from "@/components/RedirectToExternal";
import HomePage from "@/pages/HomePage";
import TwitchCallbackPage from "@/pages/(auth)/TwitchCallbackPage";
import ForgePage from "@/pages/(forge)/ForgePage";

import ForgeShieldPage from "@/pages/(forge)/shields/ForgeShieldPage";
import HunterKillScoreboardPage from "@/pages/(assets)/HunterKillScoreboardPage";
import AlreadyHasShieldGuard from "@/guards/AlreadyHasShieldGuard";
import SubscribedGuard from "@/guards/SubscribedGuard";
import ShieldUploadedPage from "@/pages/(forge)/ShieldUploadedPage";
import WithoutNavBarLayout from "@/layouts/WithoutNavBaseLayout";
import SubscribePage from "@/pages/SubscribePage";
import NewShieldPage from "@/pages/(assets)/NewShieldPage";

// Detect subdomain
const hostnameParts = window.location.hostname.split(".");
var subdomain = hostnameParts.length > 2 ? hostnameParts[0] : "main"; // 'main' for vanderheim.com

// Get host and protocol
const host_including_top_level_domain = window.location.host;
const protocol = window.location.protocol;

console.log(`Subdomain: ${subdomain}`);
console.log(
    `Host including top-level domain: ${host_including_top_level_domain}`,
);

// Define routes for each subdomain
const routesForMain = [
    {
        path: "/",
        element: (
            <RedirectToExternal
                url={`${protocol}//village.${host_including_top_level_domain}`}
            />
        ),
    },
];

const routesForWallhalla = [
    {
        path: "/",
        element: <WallhallaViewer />,
    },
    {
        path: "/wallhalla-viewer",
        element: <AnniversaryPage />,
    },
];

const routesForAssets = [
    {
        path: "/",
        element: <AssetsLayout />,
        children: [
            {
                path: "hunter-kill-scoreboard",
                element: <HunterKillScoreboardPage />,
            },
            {
                path: "latest-shield",
                element: <NewShieldPage />,
            }
        ],
    },
];

const routesForLonghouse = [
    {
        path: "/",
        element: (
            <RedirectToExternal
                url={`${protocol}//village.${host_including_top_level_domain}`}
            />
        ),
    },
];

const routesForVillage =
    process.env.NODE_ENV === "production"
        ? [
              {
                  path: "/",
                  element: <HomePage />,
              },
              {
                  path: "/auth/twitch/callback",
                  element: <TwitchCallbackPage />,
              },
              {
                  path: "/subscribe",
                  element: (
                      <AuthGuard>
                          <WithoutNavBarLayout>
                              <SubscribePage />
                          </WithoutNavBarLayout>
                      </AuthGuard>
                  ),
              },
              {
                  path: "/forge",
                  element: (
                      <AuthGuard>
                          <SubscribedGuard>
                              <AlreadyHasShieldGuard>
                                  <WithoutNavBarLayout>
                                      <ForgeLayout />
                                  </WithoutNavBarLayout>
                              </AlreadyHasShieldGuard>
                          </SubscribedGuard>
                      </AuthGuard>
                  ),
                  children: [
                      {
                          index: true,
                          element: <ForgePage />,
                      },
                      {
                          path: "shields/berserkir",
                          element: (
                              <ForgeShieldPage rawSvgContent={berserkirSvg} />
                          ),
                      },
                      {
                          path: "shields/midgard",
                          element: (
                              <ForgeShieldPage rawSvgContent={midgardSvg} />
                          ),
                      },
                      {
                          path: "shields/gebo",
                          element: <ForgeShieldPage rawSvgContent={geboSvg} />,
                      },
                      {
                          path: "shields/isaz",
                          element: <ForgeShieldPage rawSvgContent={isazSvg} />,
                      },
                      {
                          path: "shields/kross",
                          element: <ForgeShieldPage rawSvgContent={krossSvg} />,
                      },
                      {
                          path: "shields/norbausta",
                          element: (
                              <ForgeShieldPage rawSvgContent={norbaustaSvg} />
                          ),
                      },
                      {
                          path: "shields/norbvestur",
                          element: (
                              <ForgeShieldPage rawSvgContent={norbvesturSvg} />
                          ),
                      },
                      {
                          path: "shields/stjarna",
                          element: (
                              <ForgeShieldPage rawSvgContent={stjarnaSvg} />
                          ),
                      },
                      {
                          path: "shields/uruz",
                          element: <ForgeShieldPage rawSvgContent={uruzSvg} />,
                      },
                      {
                          path: "shields/zuru",
                          element: <ForgeShieldPage rawSvgContent={zuruSvg} />,
                      },
                  ],
              },
              {
                  path: "/shield-uploaded",
                  element: (
                      <AuthGuard>
                          <SubscribedGuard>
                              <ShieldGuard>
                                  <WithoutNavBarLayout>
                                      <ShieldUploadedPage />
                                  </WithoutNavBarLayout>
                              </ShieldGuard>
                          </SubscribedGuard>
                      </AuthGuard>
                  ),
              },
          ]
        : [
              {
                  path: "/",
                  element: <HomePage />,
              },
              {
                  path: "/auth/twitch/callback",
                  element: <TwitchCallbackPage />,
              },
              {
                  path: "/subscribe",
                  element: (
                      <AuthGuard>
                          <WithoutNavBarLayout>
                              <SubscribePage />
                          </WithoutNavBarLayout>
                      </AuthGuard>
                  ),
              },
              {
                  path: "/forge",
                  element: (
                      <AuthGuard>
                          <SubscribedGuard>
                              <AlreadyHasShieldGuard>
                                  <BaseLayout>
                                      <ForgeLayout />
                                  </BaseLayout>
                              </AlreadyHasShieldGuard>
                          </SubscribedGuard>
                      </AuthGuard>
                  ),
                  children: [
                      {
                          index: true,
                          element: <ForgePage />,
                      },
                      {
                          path: "shields/berserkir",
                          element: (
                              <ForgeShieldPage rawSvgContent={berserkirSvg} />
                          ),
                      },
                      {
                          path: "shields/midgard",
                          element: (
                              <ForgeShieldPage rawSvgContent={midgardSvg} />
                          ),
                      },
                      {
                          path: "shields/gebo",
                          element: <ForgeShieldPage rawSvgContent={geboSvg} />,
                      },
                      {
                          path: "shields/isaz",
                          element: <ForgeShieldPage rawSvgContent={isazSvg} />,
                      },
                      {
                          path: "shields/kross",
                          element: <ForgeShieldPage rawSvgContent={krossSvg} />,
                      },
                      {
                          path: "shields/norbausta",
                          element: (
                              <ForgeShieldPage rawSvgContent={norbaustaSvg} />
                          ),
                      },
                      {
                          path: "shields/norbvestur",
                          element: (
                              <ForgeShieldPage rawSvgContent={norbvesturSvg} />
                          ),
                      },
                      {
                          path: "shields/stjarna",
                          element: (
                              <ForgeShieldPage rawSvgContent={stjarnaSvg} />
                          ),
                      },
                      {
                          path: "shields/uruz",
                          element: <ForgeShieldPage rawSvgContent={uruzSvg} />,
                      },
                      {
                          path: "shields/zuru",
                          element: <ForgeShieldPage rawSvgContent={zuruSvg} />,
                      },
                  ],
              },
              {
                  path: "/dashboard",
                  element: (
                      <AuthGuard>
                          <SubscribedGuard>
                              <ShieldGuard>
                                  <BaseLayout>
                                      <DashboardLayout />
                                  </BaseLayout>
                              </ShieldGuard>
                          </SubscribedGuard>
                      </AuthGuard>
                  ),
                  children: [
                      {
                          index: true,
                          element: <DashboardPage />,
                      },
                  ],
              },
              {
                  path: "/shield-uploaded",
                  element: (
                      <AuthGuard>
                          <SubscribedGuard>
                              <ShieldGuard>
                                  <BaseLayout>
                                      <ShieldUploadedPage />
                                  </BaseLayout>
                              </ShieldGuard>
                          </SubscribedGuard>
                      </AuthGuard>
                  ),
              },
          ];

// Select the routes based on the detected subdomain
let selectedRoutes;

// Set anything sent to https://f5dd-2a00-23a8-4ca1-2700-1142-82be-dd34-a05f.ngrok-free.app/ to the village routes, for testing purposes, set subdomain to "village"
// Set anything sent to http://localhost:5173/ to the village routes, for testing purposes, set subdomain to "village"

if (process.env.NODE_ENV === "development") {
    const isNgrok = host_including_top_level_domain.includes("ngrok-free.app");
    const isLocalhost = host_including_top_level_domain.includes("localhost");

    if (isNgrok) {
        subdomain = "village";
    }

    if (isLocalhost) {
        subdomain = "village";
    }
}

switch (subdomain) {
    case "wallhalla":
        selectedRoutes = routesForWallhalla;
        break;
    case "longhouse":
        selectedRoutes = routesForLonghouse;
        break;
    case "village":
        selectedRoutes = routesForVillage;
        break;
    case "assets":
        selectedRoutes = routesForAssets;
        break;
    default:
        selectedRoutes = routesForMain; // Default to main domain routes
}

// Create the router with the selected routes
export const router = createBrowserRouter(selectedRoutes);
