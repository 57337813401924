import { gql } from "@apollo/client";

export const CREATE_SHIELD_MUTATION = gql`
    mutation CreateShieldMutation($imageData: String!) {
        createShield(imageData: $imageData) {
            success
            message
            shield {
                id
                imageUrl
                createdAt
                shieldNumber
                updatedAt
            }
        }
    }
`;
