import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    ApolloLink,
    split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createGraphQLWSLink } from "./graphqlWSAdapter";

// Environment variables
const API_URL = import.meta.env.VITE_VANDERHEIM_API_URL as string;
const WS_API_URL = import.meta.env.VITE_VANDERHEIM_WS_API_URL as string;

console.log(`API_URL: ${API_URL}`);
console.log(`WS_API_URL: ${WS_API_URL}`);

// HTTP Link for queries and mutations
const httpLink = new HttpLink({
    uri: API_URL,
    credentials: "include",
});

// Middleware for CSRF token
const csrfLink = new ApolloLink((operation, forward) => {
    const csrfToken = document.cookie
        .split("; ")
        .find((row) => row.startsWith("csrftoken="));
    if (csrfToken) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                "X-CSRFToken": csrfToken.split("=")[1],
            },
        }));
    }
    return forward(operation);
});

// WebSocket Link for subscriptions
const wsLink = createGraphQLWSLink({
    url: WS_API_URL,
    connectionParams: {
        credentials: "include",
    },
});

// Use `split` to send data to either HTTP or WebSocket based on operation type
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink, // Send subscriptions over WebSocket
    ApolloLink.from([csrfLink, httpLink]) // Send queries and mutations over HTTP
);

// Initialize Apollo Client
const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});

export default client;
