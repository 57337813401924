import React, { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import { LATEST_SHIELD_SUBSCRIPTION } from "@/graphql/subscriptions/shieldSubscriptions";

const NewShieldPage: React.FC = () => {
    const { data, loading, error } = useSubscription(LATEST_SHIELD_SUBSCRIPTION);

    if (loading) return <p>Loading subscription data...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="">
            <img src={data.shield.imageUrl} alt="Shield" />
        </div>
    );
};

export default NewShieldPage;
