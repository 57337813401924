import ReactDOM from "react-dom/client";
import { router } from "./router/browserRouter";
import { RouterProvider } from "react-router-dom";
import ApolloProvider from "./providers/ApolloProvider";
import { AuthProvider } from "./providers/AuthProvider";
import ThemeProvider from "./providers/ThemeProvider";
import "./index.css";
import "@stripe/stripe-js";

ReactDOM.createRoot(document.getElementById("root")!).render(
        <ThemeProvider>
            <ApolloProvider>
                <AuthProvider>
                        <RouterProvider router={router} />
                </AuthProvider>
            </ApolloProvider>
        </ThemeProvider>

);
