import { gql } from "@apollo/client";

export const LATEST_SHIELD_SUBSCRIPTION = gql`
    subscription MySubscription {
        shield {
            id
            imageUrl
            shieldNumber
        }
    }
`;